@charset 'UTF-8';

.thumb {
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include transition_c(all 0.3s);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.zimg {
  display: block;
  opacity: 1 !important;
  @include transition_c(none);

  * {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .zimg_frame {
    overflow: hidden;
    background: $gray;
  }
  .zimg_bg {
    height: 100%;
    width: 100%;
    @include transition_c(all 0.5s ease);

    .thumb {
      height: 100%;
    }
  }
  .zimg_txt {
    @include transition_c(opacity .3s);
  }
  @include PC {
    &:hover {
      .zimg_bg {
        @include transform_c(scale(1.1));
      }
      .zimg_txt {
        opacity: .5;
      }
    }
  }
}

.cmn_btn {
  display: block;
  width: 100%;
  max-width: 320px;
  height: 62px;
  @include jost;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid $colortext;
  background: $colortext;
  color: #fff;
  text-align: center;
  position: relative;
  opacity: 1 !important;
  padding-top: 23px;
  letter-spacing: 1.4px;

  &,* {
    backface-visibility: hidden;
  }
  i {
    display: block;
    width: 31px;
    height: 14px;
    right: -32px;
    top: 50%;
    margin-top: -7px;
    position: absolute;
    @include transition_c(all linear .2s);

    &::before,&::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
    }
    &::before {
      width: 100%;
      height: 1px;
      left: 0;
      background: $colortext;
    }
    &::after {
      width: 10px;
      height: 10px;
      right: 1px;
      border-right: 1px solid $colortext;
      border-bottom: 1px solid $colortext;
      @include transform_c(rotate(-45deg));
      margin-top: -5px;
    }
  }
  &.btn_trans {
    background: transparent;
    color: $colortext;
  }
  &.btn_white {
    border-color: #fff;
    color: #fff;

    i {

      &::before {
        background: #fff;
      }
      &::after {
        border-color: #fff;
      }
    }
  }
  &.btn_reverse {
    border-color: #fff;
    background: #fff;
    color: $colortext;
  }
  @include PC {

    &:hover {
      color: $colortext;
      background: #fff;
      border-color: #fff;

      i {
        right: -42px;
        width: 41px;
      }
    }
    &.btn_trans:not(.btn_white):hover {
      border-color: $colortext;
    }
    &.btn_reverse:hover,
    &.hov_black:hover {
      background: $colortext;
      color: #fff;
      border-color: $colortext;
    }
    &.btn_border:hover {
      border-color: $colortext;
    }
  }
  @include max-screen(767px) {
    max-width: 270px;
    padding-top: 22px;
    letter-spacing: 1.2px;

    i {
      width: 30px;
      right: -31px;
    }
  }
  @include max-screen(374px) {
    max-width: 240px;
  }
}

.cmn_hd {
  height: 130px;
  background: url(../img/common/other/bg_brown.jpg) no-repeat top left;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  &__inner {
    width: 100%;
  }
  &__title {
    @include jost;
    width: 100%;
    margin-bottom: -46px;

    &--en {
      color: #fff;
      font-size: 75px;
      margin-bottom: 18px;
      letter-spacing: 10.5px;
      position: relative;
      left: -3px;
    }
    &--jp {
      @include off-font-smooth;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 5px;
    }
  }
  @include max-screen(767px) {
    height: 54px;

    &__title {
      margin-bottom: -26px;

      &--en {
        font-size: 36px;
        margin-bottom: 7px;
        letter-spacing: 5px;
        left: 0;
      }
      &--jp {
        font-size: 13px;
        letter-spacing: 4px;
      }
    }
  }
}

.breadcrumbs {
  font-size: 0;
  position: relative;
  z-index: 2;
  padding: 25px 0 0;

  ul {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    text-align: right;

    @include min-screen(768px) {
      padding-left: 220px;
    }
    li {
      display: inline;
      position: relative;

      a {
        display: inline-block;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        color: inherit;
        @include transition_c(opacity .3s);
        text-decoration: underline;
        margin-right: 3px;
      }
      span {
        color: $colortext;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  @include max-screen(767px) {
    padding-top: 43px;

    &.al_left ul {
      text-align: left;
    }
    ul {
      padding-right: 17px;
    }
  }
}

.cmn_bg {
  background: $gray;
  @include clearfix();
}

.cmn_pagenavi {
  font-size: 0;
  @include tajawal;
  text-align: center;

  &__prev,&__next,&__sl,.wp-pagenavi {
    display: inline-block;
    vertical-align: middle;
  }
  &__prev,&__next {
    width: 30px;
    height: 30px;

    a {
      display: block;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 20px 14px;
      background-position: center center;
    }
  }
  &__prev {
    margin-right: 35px;

    a {
      background-image: url(../img/common/icon/ico_prev_page.svg);
    }
  }
  &__next {
    margin-left: 35px;

    a {
      background-image: url(../img/common/icon/ico_next_page.svg);
    }
  }
  .wp-pagenavi {

    a,span {
      width: 30px;
      height: 30px;
      font-size: 18px;
      margin: 0 7px;
      @include border-radius(50%);
      display: inline-block;
      vertical-align: top;
      font-weight: bold;
      padding-top: 7px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    a {
      opacity: 1 !important;
    }
    span {

      &.current {
        background: #000;
        color: #fff;
      }
      &.extend {
        margin: 0 10px;
      }
    }
  }
  @include PC {

    .wp-pagenavi a:hover {
      background: #000;
      color: #fff;
    }
  }
  @include max-screen(767px) {
    position: relative;
    padding-bottom: 51px;
    max-width: 339px;
    margin: 0 auto;

    &__prev,&__next {
      position: absolute;
      bottom: 0;
      margin: 0;
    }
    &__prev {
      left: -5px;
    }
    &__next {
      right: -5px;
    }
    .wp-pagenavi {
      width: auto;
      margin: 0 -7px;

      a,span {
        margin: 0 7px;
        text-align: center;
        padding-top: 6px;
      }
      span.current {
        margin: 0 11px;
      }
    }
  }
  @include max-screen(374px) {

    &__prev {
      left: 0;
    }
    &__next {
      right: 0;
    }
    .wp-pagenavi {
      margin: 0 -3px;

      a,span {
        margin: 0 3px;
      }
      span.extend {
        margin: 0 3px;
      }
    }
  }
}

.cmn_pagination {
  font-size: 0;
  margin: 71px auto 88px;
  max-width: 1242px;
  padding: 0 18px;

  &.null .cmn_pagination__list {
    padding-top: 0;
  }
  &__prev,&__next,&__list {
    display: inline-block;
    vertical-align: top;
  }
  &__prev,&__next {
    width: 393px;

    a {
      display: block;
      position: relative;
      padding-top: 89px;

      &::before,&::after {
        position: absolute;
      }
      &::before {
        content: "";
        width: 58px;
        height: 58px;
        border: 1px solid $colortext;
        @include border-radius(50%);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 20px 14px;
        top: 0;
        @include transition_c(background-color .3s);
        backface-visibility: hidden;
      }
      &::after {
        font-family: $tajawal;
        line-height: 1;
        font-weight: bold;
        font-size: 20px;
        top: 19px;
        letter-spacing: 1.5px;
      }
      @include PC {

        &:hover::before {
          background-color: #000;
        }
      }
    }
    .thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .zimg_frame {
      margin-bottom: 21px;
    }
    .zimg_bg {
      @include aspect-ratio(340,250);
    }
    .title {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .info {
      font-size: 13px;
      font-weight: bold;
      color: #8D9BA8;
      letter-spacing: 0.7px;
    }
  }
  &__prev {

    a {

      &::before {
        left: 0;
        background-image: url(../img/common/icon/ico_prev.svg);
      }
      &::after {
        content: "PREV";
        left: 85px;
      }
      .content {
        padding-left: 53px;
      }
      @include PC {

        &:hover::before {
          background-image: url(../img/common/icon/ico_prev_white.svg);
        }
      }
    }
  }
  &__next {

    a {

      &::before {
        right: 0;
        background-image: url(../img/common/icon/ico_next.svg);
      }
      &::after {
        content: "NEXT";
        right: 85px;
      }
      .content {
        padding-right: 53px;
      }
      @include PC {

        &:hover::before {
          background-image: url(../img/common/icon/ico_next_white.svg);
        }
      }
    }
  }
  &__list {
    width: calc(100% - 786px);
    text-align: center;
    padding-top: 210px;

    a {
      display: inline-block;
      vertical-align: top;
      font-weight: bold;
      font-size: 18px;
      background: url(../img/common/icon/ico_back_archive.svg) no-repeat top center;
      background-size: 24px 24px;
      padding: 36px 10px 0;
      letter-spacing: 1.3px;
    }
  }
  @include screen(768px,1023px) {

    &__list {
      width: calc(100% - 560px);
      padding-top: 167px;

      a {
        font-size: 16px;
      }
    }
    &__prev,&__next {
      width: 280px;

      .content {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
  @include max-screen(767px) {
    margin: 60px 0 73px;

    &__prev,&__next {
      width: calc((100% - 96px) / 2);

      a {
        padding-top: 49px;

        &::before {
          width: 30px;
          height: 30px;
          background-size: 10px 7px;
        }
        &::after {
          font-size: 16px;
          top: 7px;
          letter-spacing: 1.2px;
        }
      }
      .zimg_frame {
        margin-bottom: 14px;
      }
      .content {
        padding: 0 !important;

        .title {
          font-size: 13px;
          line-height: 1.65;
          margin-bottom: 8px;
        }
        .info {
          font-size: 11px;
          letter-spacing: 0.6px;
        }
      }
    }
    &__prev {

      a::after {
        left: 42px;
      }
    }
    &__next {

      a::after {
        right: 37px;
      }
    }
    &__list {
      width: 96px;
      padding-top: 61px;

      a {
        font-size: 13px;
        padding: 37px 20px 0;
        line-height: 1.5;
      }
    }
  }
  @include max-screen(374px) {

    &__list {
      width: 80px;

      a {
        font-size: 12px;
      }
    }
    &__prev,&__next {
      width: calc((100% - 80px) / 2);

      .content .info br {
        display: none;
      }
    }
  }
}

.cmn_pagenav {

  &__title {
    text-align: center;
    font-family: $jost;
    font-size: 40px;
    font-weight: 500;
    padding: 0 18px;
    letter-spacing: 4px;
    margin-bottom: 44px;
    @include off-font-smooth;
  }
  &__list {
    width: 87.5%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    li {
      width: 50%;
      margin-bottom: 37px;

      .content {

        .zimg_frame {
          height: 230px;
          position: relative;
          display: flex;

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: $colortext;
            opacity: .2;
            z-index: 1;
          }
          .note {
            margin: auto;
            display: inline-block;
            vertical-align: top;
            width: auto;
            color: #fff;
            @include jost;
            border: 1px solid #fff;
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            height: 62px;
            z-index: 2;
            position: relative;
            padding: 17px 47px 0 44px;
            letter-spacing: 3.3px;
          }
        }
        .zimg_bg {
          position: absolute;
          top: 0;
          left: 0;
        }
        .title {
          position: relative;
          padding-left: 47px;
          letter-spacing: 1.4px;
          font-size: 18px;
          font-weight: bold;
          margin-top: 18px;

          &::before {
            content: "";
            display: block;
            width: 21px;
            height: 10px;
            background: url(../img/common/icon/ico_ar_right_black_bold.svg) no-repeat top left;
            background-size: 100% 100%;
            position: absolute;
            top: 8px;
            left: 0;
          }
        }
      }
    }
  }
  @include max-screen(1023px) {

    &__list {
      width: 100%;
      padding: 0 18px;
    }
  }
  @include screen(768px,1023px) {

    &__list {

      li {

        .content {

          .zimg_frame .note {
            font-size: 20px;
          }
          .title {
            font-size: 16px;
            padding-left: 35px;
          }
        }
      }
    }
  }
  @include max-screen(767px) {

    &__list {

      li {
        width: 100%;
        margin-bottom: 36px;

        .content {

          .zimg_frame {
            height: 110px;

            .note {
              font-size: 16px;
              height: 37px;
              padding: 9px 31px 0 28px;
              letter-spacing: 1.9px;
            }
          }
          .title {
            font-size: 15px;
            margin-top: 13px;
            padding-left: 33px;
            letter-spacing: 1.2px;

            &::before {
              top: 6px;
            }
          }
        }
        &:nth-child(2),&:nth-child(3),&:last-child {

          .content .zimg_frame .note {
            width: 137px;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
