@charset "UTF-8";

/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  line-height: 0;
}
.clearfix {display: inline-block;}
/* exlude MacIE5 \*/
* html .clearfix {height: 1%}
.clearfix {display: block;}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
p {
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
input, textarea {
  margin: 0;
  font-size: 100%;
  resize: none;
}
input {
  word-break: normal;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}
dl, dt, dd, th, td {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  /*font-size: 100%;*/
  font-weight: normal;
  margin: 0;
  padding: 0;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, dialog {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
img {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 0;
  max-width:100%;
  height:auto;
}
table img {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 0;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section {display: block;}
nav ul {list-style: none;}
*, *:before, *:after {
  @include add_prefix(box-sizing, border-box);
  @if $font_smooth {
    @include font-smooth();
  }
}
*:focus {outline: none!important;}
label, select, button {cursor: pointer;}

// Remove select appearance for IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  select::-ms-expand {
    display: none;
  }
}
@media screen\0 {
  select::-ms-expand {
    display: none;
  }
}
em,i {
  font-style: normal;
}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
  outline: none;
  transition: 0.3s;
  color: $colortext;
  text-decoration: none;
  @include PC {
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
}
/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/
.ffN {@include ffN;}
.ffM {@include ffM;}
.ffYG {@include ffYG;}
.ffYM {@include ffYM;}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
html {
  font-size: 62.5%;
  @include add_prefix(text-size-adjust, 100%);
  @if $font_feature_settings_palt {
    @include add_prefix(font-feature-settings, "palt");
  }
}
body {
  font-family: $yugothic;
  @include font-size(15);
  width: 100%;
  color: $colortext;
  margin: 0;
  padding: 74px 0 0;
  line-height: 1.6;
  letter-spacing: 0;
  height: 100%;
  word-break: break-word;

  &.no_fixed_hd {
    padding-top: 0;
  }
  &:not(.anim-end) {

  }
  @include max-screen(1129px) {
    padding-top: 106px;

    &.menu_opened {
      overflow: hidden;
    }
  }
}
#wrap {
  overflow: hidden;
  position: relative;
}
main {
  display: block;
}
.lazy {
  @include transition_c(opacity .3s);
  opacity: 0;
}
.lazy[data-ll-status="loaded"] {
  opacity: 1;
}
.wcm,.wcm2 {
  margin: 0 auto;
  padding: 0 18px;
}
.wcm {
  max-width: $widthcm;
}
.wcm2 {
  max-width: $widthcm2;
}
.sp, .SP {
  @include min-screen(768px) {display: none!important;}
}
.pc, .PC {
  @include max-screen(767px) {display: none!important;}
}
.env .wow {
  visibility: visible !important;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
// loading
.ploading {
  width: 100%;
  height: 100%;
  background: $gray;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  transition: all .8s;
  display: flex;
  flex-flow: wrap column;
  justify-content: center;

  &,* {
    backface-visibility: hidden;
  }
  &__content {
    font-size: 0;

    &--logo,&--bar {
      display: block;
      margin: 0 auto;
    }
    &--logo {
      margin-bottom: 53px;
      width: 222px;
    }
    &--bar {
      width: 300px;
      height: 1px;
      position: relative;

      span {
        display: block;
        width: 0;
        height: 100%;
        background: #1E1E1E;
        @include transition_c(width .3s);
      }
    }
  }
  &.done {
    transition-delay: .8s;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  @include max-screen(767px) {

    &__content {

      &--logo {
        width: 160px;
        margin-bottom: 25px;
      }
      &--bar {
        width: 200px;
      }
    }
  }
}
