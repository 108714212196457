@charset 'UTF-8';

.header {

  &__main {
    width: 100%;
    height: 74px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,.06);
    display: flex;
    align-items: center;
    padding-left: 41px;

    &--logo {
      display: block;
      width: 153px;

      img {
        display: block;
        width: 100%;
      }
    }
    &--right {
      width: calc(100% - 153px);
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: 64px;
    }
    &--menu {
      display: flex;
      width: calc(100% - 400px);

      li {
        position: relative;
        padding-right: 32px;
        margin-right: 31px;

        &:last-child {
          padding-right: 0;
          margin-right: 0;

          &::before {
            display: none;
          }
        }
        &::before {
          content: "";
          width: 1px;
          height: 16px;
          background: #C3C3C3;
          position: absolute;
          top: 50%;
          margin-top: -7px;
          right: 0;
        }
        a {
          display: block;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 1.1px;
        }
      }
    }
    &--btn {
      float: right;
      width: 200px;
      height: 100%;
      display: block;
      color: #fff;
      line-height: 1;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      padding-top: 17px;
      letter-spacing: 2.6px;

      small {
        display: block;
        @include jost;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 2px;
        margin-bottom: 9px;
      }
      &.btn_contact {
        background: $colortext;
      }
      &.btn_event {
        background: $red;
      }
    }
  }
  @include screen(1130px,1419px) {

    &__main {
      padding-left: 20px;

      &--right {
        padding-left: 30px;
      }
      &--menu {
        width: calc(100% - 280px);

        li {
          padding-right: 20px;
          margin-right: 20px;
        }
      }
      &--btn {
        width: 140px;
      }
    }
  }
  @include max-screen(1129px) {

    &__main {
      height: 106px;
      display: block;
      padding-left: 20px;
      box-shadow: 3px 3px 6px 0px rgba(0,0,0,.06);

      &--right {
        width: auto;
        height: auto;
        padding: 0;
      }
      &--logo {
        margin-top: 20px;
        width: 137px;
      }
      &--menu {
        display: none;
      }
      &--menusp {
        display: block;
        position: absolute;
        top: 14px;
        right: 19px;
        width: 34px;
        padding-top: 25px;
        user-select: none;

        span {

          &,&::before,&::after {
            height: 1px;
            background: $colortext;
            position: absolute;
            right: 0;
            display: block;
            @include transition_c(all .3s);
            backface-visibility: hidden;
          }
          & {
            width: 100%;
            top: 0;
          }
          &::before,&::after {
            content: "";
          }
          &::before {
            width: 100%;
            top: 8px;
          }
          &::after {
            width: 17px;
            top: 16px;
          }
        }
        em {
          display: block;
          text-align: center;
          @include jost;
          text-transform: uppercase;
          font-size: 9px;
          font-weight: 500;
          letter-spacing: 2.8px;
          white-space: nowrap;
          position: relative;
        }
      }
      &--btn {
        width: 50%;
        height: 46px;
        position: absolute;
        bottom: 0;
        font-size: 13px;
        letter-spacing: 1px;
        padding-top: 16px;

        span {
          display: inline-block;
          vertical-align: top;
          position: relative;

          &::before {
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-position: top left;
            background-size: 100% 100%;
            top: 50%;
            position: absolute;
            left: 0;
          }
        }
        small {
          display: none;
        }
        &.btn_contact {
          right: 0;
          border-left: 1px solid #C3C9CC;

          span {
            padding-left: 31px;

            &::before {
              width: 19px;
              height: 19px;
              margin-top: -10px;
              background-image: url(../img/common/icon/ico_mail_white.svg);
            }
          }
        }
        &.btn_event {
          left: 0;

          span {
            padding-left: 32px;

            &::before {
              width: 21px;
              height: 18px;
              margin-top: -9px;
              background-image: url(../img/common/icon/ico_calendar_white.svg);
            }
          }
        }
      }
    }
    &__menusp {
      width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			background: $colortext;
			z-index: -1;
			visibility: hidden;
			opacity: 0;
			overflow: auto;
			@include transition_c(all .3s);

      &--inner {
        padding: 106px 18px 30px;
        position: relative;

        &::before {
          content: "";
          width: 100%;
          height: 1px;
          background: #fff;
          position: fixed;
          top: 106px;
          left: 0;
        }
      }
      &--list {
        padding-top: 37px;
        margin-bottom: 42px;

        > li {
          margin-bottom: 26px;

          &:last-child {
            margin-bottom: 0;
          }
          > a {
            padding: 0 0 16px 49px;
            border-bottom: 1px solid $darkgray;
            letter-spacing: 1.3px;

            &::before,&::after {
              content: "";
              display: block;
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-position: top left;
              position: absolute;
            }
            &::after {
              background-image: url(../img/common/icon/ico_ar_right_white.svg);
              width: 21px;
              height: 10px;
              top: 50%;
              margin-top: -11px;
              right: 14px;
            }
          }
          a {
            display: block;
            color: #fff;
            font-weight: 500;
            @include jost;
            text-transform: uppercase;
            position: relative;
          }
          .sub {
            padding: 31px 0 16px 30px;

            li {
              margin-bottom: 26px;

              &:last-child {
                margin-bottom: 0;
              }
              a {
                font-size: 13px;
                letter-spacing: 1.2px;
                padding-left: 32px;

                &::before {
                  content: "";
                  display: block;
                  width: 10px;
                  height: 10px;
                  position: absolute;
                  top: 50%;
                  left: 0;
                  margin-top: -10px;
                  border-left: 1px solid $darkgray;
                  border-bottom: 1px solid $darkgray;
                }
              }
            }
          }
          &:first-child,&:nth-child(2) {
            margin-bottom: 27px;

            > a {
              padding-left: 54px;
              padding-bottom: 14px;

              &::after {
                margin-top: -12px;
              }
            }
          }
          &:first-child > a::before {
            background-image: url(../img/common/icon/ico_home.svg);
            width: 21px;
            height: 19px;
            top: -5px;
            left: 13px;
          }
          &:nth-child(2) > a::before {
            background-image: url(../img/common/icon/ico_concept.svg);
            width: 19px;
            height: 24px;
            top: -7px;
            left: 16px;
          }
          &:nth-child(3) > a::before {
            background-image: url(../img/common/icon/ico_money.svg);
            width: 21px;
            height: 17px;
            top: -2px;
            left: 13px;
          }
          &:nth-child(4) > a::before {
            background-image: url(../img/common/icon/ico_works.svg);
            width: 25px;
            height: 17px;
            top: -2px;
            left: 11px;
          }
          &:nth-child(5) > a::before {
            background-image: url(../img/common/icon/ico_column.svg);
            width: 22px;
            height: 21px;
            top: -6px;
            left: 13px;
          }
          &:nth-child(6) > a::before {
            background-image: url(../img/common/icon/ico_news.svg);
            width: 24px;
            height: 18px;
            top: -4px;
            left: 9px;
          }
          &:nth-child(7) > a::before {
            background-image: url(../img/common/icon/ico_store.svg);
            width: 20px;
            height: 19px;
            top: -4px;
            left: 12px;
          }
          &:nth-child(8) > a::before {
            background-image: url(../img/common/icon/ico_property.svg);
            width: 22px;
            height: 19px;
            top: -4px;
            left: 13px;
          }
          &:nth-child(9) > a::before {
            background-image: url(../img/common/icon/ico_voice.svg);
            width: 25px;
            height: 21px;
            top: -5px;
            left: 12px;
          }
          &:nth-child(10) > a::before {
            background-image: url(../img/common/icon/ico_faq.svg);
            width: 21px;
            height: 21px;
            top: -6px;
            left: 13px;
          }
          &:nth-child(11) > a::before {
            background-image: url(../img/common/icon/ico_calendar_white.svg);
            width: 21px;
            height: 18px;
            top: -3px;
            left: 13px;
          }
        }
      }
      &--close {
        font-size: 0;
        text-align: center;

        a {
          display: inline-block;
          vertical-align: top;
          width: 45px;
          text-align: center;
          @include jost;
          font-size: 9px;
          font-weight: 500;
          text-transform: uppercase;
          color: #fff;
          background: url(../img/common/icon/ico_close_white.svg) no-repeat top center;
          background-size: 35px 17px;
          padding-top: 22px;
          letter-spacing: 2.5px;
        }
      }
    }
    &.menu_opened {

      .header {

        &__main {
          box-shadow: 0px 3px 3px 0px rgba(0,0,0,.1);

          &--menusp {

            span {
              background: transparent;

              &::before,&::after {
                width: 37px;
              }
              &::before {
								transform: rotate(25deg) translateX(3px) translateY(2px);
							}
							&::after {
								transform: rotate(-25deg) translateX(4px) translateY(-4px);
							}
            }
            em {
              left: -2px;
            }
          }
        }
        &__menusp {
          height: 100%;
          opacity: 1;
          z-index: 999998;
          visibility: visible;
        }
      }
    }
  }
  @include max-screen(374px) {

    &__menusp--list>li .sub {
      padding-left: 20px;
    }
  }
  @include min-screen(1130px) {

    &__menusp,&__main--menusp {
      display: none !important;
    }
  }
}

.header_form {
  height: 74px;
  padding-top: 26px;

  &__logo {
    width: 153px;
    display: block;
    margin: 0 auto;
  }
  @include max-screen(767px) {
    height: 62px;
    padding-top: 19px;

    &--logo {
      width: 137px;
    }
  }
}
