@charset 'UTF-8';

.contactBox {
  position: relative;
  padding: 0 18px;
  background: $colortext;

  &__bg {
    width: 100%;
    height: calc(100% - 51px);
    position: absolute;
    top: 0;
    left: 0;
  }
  &__inner {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    padding-top: 88px;
  }
  &__hd {
    color: #302823;
    font-size: 68px;
    width: 100%;
    position: absolute;
    top: -57px;
    left: 0;
    text-align: center;
    letter-spacing: 9.5px;
    padding-left: 10px;
    @include jost;
  }
  &__list {
    font-size: 0;
    margin-bottom: 40px;

    li {
      display: inline-block;
      vertical-align: top;
      width: 50%;

      .zimg {

        @include PC {

          &:hover .cmn_btn {

            i {
              width: 41px;
              right: -42px;
            }
          }
        }
      }
      .content {
        height: 230px;
        position: relative;

        .zimg_frame {
          height: 100%;
        }
        .zimg_bg {

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            background: #151515;
            position: absolute;
            top: 0;
            left: 0;
            opacity: .2;
            z-index: 1;
          }
        }
        .title,.cmn_btn {
          z-index: 2;
          position: absolute;
        }
        .title {
          display: block;
          top: 50%;
        }
        .cmn_btn {
          pointer-events: none;
          top: 7px;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .btn_event {

        .content {

          .title {
            width: 24px;
            height: 114px;
            margin-top: -53px;
            left: 42px;
          }
          .cmn_btn {
            left: 24px;
          }
        }
      }
      .btn_request {

        .content {

          .title {
            width: 24px;
            height: 140px;
            margin-top: -70px;
            right: 41px;
          }
          .cmn_btn {
            right: 24px;
          }
        }
      }
    }
  }
  &__wbox {
    height: 164px;
    background: #fff;
    font-size: 0;
    text-align: center;
    padding: 54px 15px 0 0;

    &--lb,&--tel {
      display: inline-block;
      vertical-align: middle;
    }
    &--lb {
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 2.4px;
      margin-right: 92px;
      position: relative;
      top: -1px;
    }
    &--tel {

      p {
        padding-left: 45px;
        position: relative;
        text-align: center;
        @include jost;

        &::before {
          content: "";
          width: 37px;
          height: 49px;
          background: url(../img/common/icon/ico_tel_black.svg) no-repeat top left;
          background-size: 100% 100%;
          position: absolute;
          left: 0;
          top: 7px;
        }
        span,em {
          display: block;
        }
        span {
          font-size: 39px;
          letter-spacing: 3.5px;
          margin-bottom: 10px;
        }
        em {
          font-size: 13px;
          padding-right: 13px;
          letter-spacing: 1px;
        }
      }
    }
  }
  @include screen(768px,1023px) {

    &__list li .content .cmn_btn {
      width: 200px;
    }
    &__wbox {
      padding-right: 0;

      &--lb {
        margin-right: 60px;
      }
    }
  }
  @include screen(768px,1199px) {

    &__list li .btn_event .content .title {
      left: 15px;
    }
    &__list li .btn_request .content .title {
      right: 15px;
    }
  }
  @include max-screen(767px) {

    &__hd {
      font-size: 36px;
      top: -30px;
      letter-spacing: 5.1px;
      padding-left: 6px;
    }
    &__inner {
      padding-top: 45px;
    }
    &__list {
      margin-bottom: 23px;

      li {
        width: 100%;

        .content {
          height: 112px;

          .zimg_bg::before {
            opacity: .3;
          }
          .cmn_btn {
            max-width: 184px;
            height: 52px;
            font-size: 14px;
            top: -2px;
            padding: 19px 0 0 4px;
            letter-spacing: 1.7px;
          }
        }
        .btn_event .content {

          .title {
            width: 14px;
            height: 59px;
            margin-top: -31px;
            left: 17px;
          }
          .cmn_btn {
            left: -3px;
          }
        }
        .btn_request .content {

          .title {
            width: 13px;
            height: 72px;
            right: auto;
            left: 17px;
            margin-top: -38px;
          }
          .cmn_btn {
            right: 2px;
          }
        }
      }
    }
    &__wbox {
      height: auto;
      padding: 0;

      &--tel {
        background: #fff;
        width: 100%;

        .content {
          padding: 15px 11px 11px;
        }
        p {
          display: inline-block;
          vertical-align: top;
          padding-left: 31px;
          margin-bottom: 11px;

          &::before {
            width: 24px;
            height: 32px;
            top: 6px;
          }
          span {
            font-size: 24px;
            letter-spacing: 3.9px;
            margin-bottom: 6px;
          }
          em {
            font-size: 11px;
            padding-right: 0;
            letter-spacing: 0.9px;
          }
        }
        small {
          background: $gray;
          font-size: 11px;
          display: block;
          font-weight: bold;
          line-height: 1;
          height: 22px;
          padding-top: 5px;
          letter-spacing: 0.9px;
        }
      }
    }
  }
}
.footer {
  background: $colortext;
  color: #fff;
  padding-top: 93px;
  overflow: hidden;

  &__frame {
    padding-left: 100px;
    padding-right: 100px;
    max-width: 1600px;
    margin: auto;
  }
  &__top {
    padding-bottom: 98px;

    &--inner {
      position: relative;
    }
  }
  &__bot {
    background: #3B3B3B;
    padding-top: 37px;
    padding-bottom: 13px;
  }
  &__inner,&__sponsors {
    max-width: 1100px;
    margin: auto;
  }
  &__gotop,&__menu,&__subtitle {
    @include jost;
  }
  &__gotop {
    width: 68px;
    height: 96px;
    position: absolute;
    bottom: 31px;
    right: -31px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 1 !important;

    i {
      margin-top: 12px;
      display: block;
      width: 68px;
      height: 68px;
      @include border-radius(50%);
      border: 1px solid #fff;
      position: relative;

      &::before {
        content: "";
        width: 13px;
        height: 28px;
        position: absolute;
        top: 19px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: url(../img/common/icon/ico_gotop.svg) no-repeat top left;
        background-size: 100% 100%;
        @include transition_c(top .3s);
      }
    }
    @include PC {

      &:hover i::before {
        top: 14px;
      }
    }
  }
  &__logo {
    display: block;
    width: 236px;
    margin: 0 0 67px 2px;

    img {
      display: block;
      width: 100%;
    }
  }
  &__menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -15px 35px;

    > li {
      width: 33.33333%;
      padding: 0 15px;
      max-width: 330px;

      a {
        display: block;
        width: 100%;
        font-weight: 500;
        position: relative;
        color: #fff;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
        }
      }
      > a {
        border-bottom: 1px solid $darkgray;
        font-size: 16px;
        letter-spacing: 1.6px;
        padding-bottom: 18px;
        margin-bottom: 37px;

        &::before {
          background: url(../img/common/icon/ico_ar_right_white.svg) no-repeat top left;
          background-size: 100% 100%;
          width: 21px;
          height: 10px;
          right: 0;
          margin-top: -16px;
        }
        &.spec {
          padding-left: 3px;
        }
      }
      .sub {
        margin-top: 43px;

        li {
          margin-bottom: 32px;

          a {
            font-size: 15px;
            padding-left: 32px;
            letter-spacing: 1.4px;

            &::before {
              left: 0;
              top: -2px;
              width: 10px;
              height: 10px;
              border-left: 1px solid $darkgray;
              border-bottom: 1px solid $darkgray;
            }
          }
        }
      }
    }
  }
  &__subtitle {
    font-size: 28px;
    letter-spacing: 3.9px;
    margin-bottom: 35px;
  }
  &__parts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -10px;

    li {
      width: 20%;
      padding: 0 10px;
      max-width: 220px;

      a,img {
        display: block;
      }
      img {
        width: 100%;
      }
      p {
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        margin-top: 14px;
        letter-spacing: 1px;
      }
    }
  }
  &__sponsors {
    margin-bottom: 40px;

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -5px;

      li {
        width: 16.66666%;
        padding: 0 5px;
        max-width: 180px;

        a,img {
          display: block;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  &__copyright {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
  }
  @include screen(768px,1399px) {

    &__gotop {
      right: -84px;
    }
    &__frame {
      max-width: 1600px;
      padding-left: 100px;
      padding-right: 100px;
    }
  }
  @include screen(768px,1023px) {

    &__menu>li {

      a {
        font-size: 15px;
      }
      .sub li {
        margin-bottom: 18px;

        a {
          font-size: 14px;
          line-height: 1.5;
          padding-left: 20px;
        }
      }
    }
    &__parts {
      margin-bottom: -15px;
      justify-content: center;

      li {
        width: 33.3333%;
        margin-bottom: 15px;
      }
    }
    &__sponsors ul {
      justify-content: center;

      li {
        width: 25%;
        margin-bottom: 10px;
      }
    }
  }
  @include max-screen(767px) {
    padding-top: 36px;

    &__frame {
      padding-left: 18px;
      padding-right: 18px;
    }
    &__top {
      padding-bottom: 24px;
    }
    &__bot {
      padding-top: 25px;
      padding-bottom: 12px;
    }
    &__logo {
      width: 137px;
      margin-bottom: 31px;
    }
    &__menu {
      margin: 0 -10px 18px;

      > li {
        width: 50%;
        max-width: 50%;
        padding: 0 10px;

        &:not(:first-child) {
          flex: 1;
        }
        &:first-child {
          width: 100%;
          max-width: 100%;
        }
        > a {
          font-size: 13px;
          letter-spacing: 1px;
          padding-bottom: 15px;
          margin-bottom: 32px;

          &::before {
            width: 21px;
            height: 10px;
            margin-top: -12px;
          }
          &.spec {
            padding-left: 0;
          }
        }
        .sub {
          margin: -8px 0 41px;
          padding-left: 21px;

          li {
            margin-bottom: 20px;

            a {
              font-size: 13px;
              padding-left: 27px;
              letter-spacing: 1.2px;
            }
          }
        }
      }
    }
    &__subtitle {
      font-size: 20px;
      letter-spacing: 2.8px;
      margin-bottom: 22px;
    }
    &__parts {

      li {
        width: 50%;
        max-width: 50%;
        margin-bottom: 19px;

        p {
          margin-top: 10px;
          font-weight: 400;
        }
      }
    }
    &__sponsors {
      margin-bottom: 13px;

      ul {
        margin: 0 -2.5px;

        li {
          width: 33.33333%;
          max-width: 33.33333%;
          padding: 0 2.5px;
          margin-bottom: 7px;
        }
      }
    }
    &__copyright {
      font-weight: 400;
    }
  }
}

.footer_form {
  text-align: center;
  font-size: 11px;
  padding: 21px 18px;
  background: $gray;

  @include max-screen(767px) {
    padding: 30px 18px;
    font-size: 12px;
    letter-spacing: 1px;
  }
}
