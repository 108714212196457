@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

// CUSTOM

.leftCol {
  width:70%;
  float:left;
}
.rightCol {
  width:30%;
  float:right;
}

.cmsContent:not(.column-box) {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  word-wrap: break-word;
  @include clearfix();

  ul,ol {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  ul {
    &[style*="list-style-type: circle;"] {
      li {
        list-style-type: circle;
      }
    }
    &[style*="list-style-type: square;"] {
      li {
        list-style-type: square;
      }
    }
    li {
      list-style: disc;
    }
  }
  ol {
    &[style*="list-style-type: lower-roman;"] {
      li {
        list-style-type: lower-roman;
      }
    }
    &[style*="list-style-type: lower-alpha;"] {
      li {
        list-style-type: lower-alpha;
      }
    }
    &[style*="list-style-type: lower-greek;"] {
      li {
        list-style-type: lower-greek;
      }
    }
    &[style*="list-style-type: upper-alpha;"] {
      li {
        list-style-type: upper-alpha;
      }
    }
    &[style*="list-style-type: upper-roman;"] {
      li {
        list-style-type: upper-roman;
      }
    }
    li {
      list-style-type: decimal;
    }
  }
  iframe {max-width: 100%!important;}
  img{
    max-width:100%;
    height:auto;
    max-height:100%;
    width: auto;
  }
  i,em {
    font-style: italic;
  }
  * {

    &:last-child {
      margin-bottom: 0;
    }
  }
  font-size: 15px;

  h2,h3,h4 {
    font-weight: bold;
  }
  h2 {
    font-size: 22px;
    border-bottom: 3px solid $colortext;
    letter-spacing: 0.7px;
    padding-bottom: 8px;
    margin-bottom: 35px;
  }
  h3 {
    font-size: 20px;
    background: #fff;
    padding: 15px 20px;
    letter-spacing: 0.6px;
    margin-bottom: 32px;
  }
  h4 {
    font-size: 18px;
    //border-left: 3px solid $colortext;
    padding: 0 5px 0 17px;
    letter-spacing: 0.55px;
    line-height: 30px;
    margin-bottom: 24px;
    position: relative;
    padding-left: 20px;
    &:before{
      content: "";
      width: 3px ;
      height: calc(100% - 8px);
      background: $colortext;
      position: absolute;
      left: 0;
      top: 3px;

    }
  }
  p {
    line-height: 2;
    margin-bottom: 15px;
  }
  a {
    text-decoration: underline;
  }
  @include max-screen(767px) {

    h2 {
      margin-bottom: 32px;
    }
    h3 {
      line-height: 1.5;
      padding: 16px 20px 18px;
      margin-bottom: 20px;
    }
    h4 {
      padding-left: 16px;
      margin-bottom: 16px;
    }
  }
}

.pagingDt {
  position:relative;
  width:80%;
  margin:95px auto;
  font-size:14px;
}
.pagingDt a {
  text-decoration:underline;
}
.pagingDt .prev {
  background:url(../../img/blog/ico_prev.png) no-repeat left center;
  width:145px;
  float:left;
  padding-left:20px;
}
.pagingDt .next {
  background:url(../../img/blog/ico_next.png) no-repeat right center;
  width:145px;
  float:right;
  padding-right:20px;
}
.pagingDt .list {
  position:absolute;
  left:50%;
  top:50%;
  margin-left:-54px;
  margin-top:-9px;
}
